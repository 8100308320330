import { config } from 'config';
import { httpPost } from 'utils/http';

export const changePassword = (oldPassword: string, password: string, token: string): Promise<Response> => {
  const payload = { old_password: oldPassword, password };
  return fetch(`${config.apiBaseURL}/member/v2/laas/password`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const deactivateSession = (token: string): Promise<Response> => {
  return httpPost(`${config.apiBaseURL}/member/v2/laas/logout`, {}, token);
};
