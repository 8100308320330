import { deactivateSession } from 'api/memberApi';
import { clearCredentials, loadCredentials } from 'auth/persister';
import { config } from 'config';

export async function clearSession(): Promise<void> {
  const credentials = loadCredentials();
  if (!credentials) {
    return;
  }

  await deactivateSession(credentials.token);
  clearCredentials();
}

export function logoutUser(): void {
  clearSession()
    .catch(() => {}) // previous implementation did nothing with the error
    .finally(() => {
      window.location.assign(`${config.basename}`);
    });
}
